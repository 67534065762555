import React, { useState, useRef } from "react";
import { useClickAway } from "react-use";

function InfoTooltip({
  message,
  onHover = false,
  children = null,
  parentClass = "",
  childClass = "",
  tooltipClass = "",
  left = false,
  disabled = false,
  minwidth = false,
}) {
  const ref = useRef(null);
  const [isPopoverOpen, setPopoverOpen] = useState(false);

  useClickAway(ref, () => {
    setPopoverOpen(false);
  });

  if (disabled) {
    return children;
  }

  return (
    <div
      className={`relative inline-block text-black ${
        onHover ? "infoToolTip-hover" : ""
      } ${parentClass}`}
    >
      {children && minwidth ? (
        <div
          className={childClass}
          style={{minWidth: "500px"}}
          onClick={() => !onHover && setPopoverOpen(true)}
        >
          {children}
        </div>
      ) : children ? (
        <div
          className={childClass}
          onClick={() => !onHover && setPopoverOpen(true)}
        >
          {children}
        </div>
      )
      : (
        <div>
          <svg
            onClick={() => setPopoverOpen(true)}
            style={{
              marginRight: "10px",
            }}
            className="fill-current text-purple-600"
            width="20"
            height="20"
            viewBox="0 0 20 20"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <circle cx="11.0001" cy="11" r="6.36364" fill="white" />
            <path d="M10 0C4.48636 0 0 4.48636 0 10C0 15.5136 4.48636 20 10 20C15.5136 20 20 15.5136 20 10C20 4.48636 15.5136 0 10 0ZM9.35364 5.71727C9.69 5.38091 10.3073 5.38091 10.6445 5.72636C10.8082 5.89 10.9073 6.12636 10.9073 6.36273C10.9073 6.59909 10.8082 6.83545 10.6445 7.00818C10.4718 7.17182 10.2345 7.27182 9.99818 7.27182C9.76182 7.27182 9.52546 7.17182 9.35364 7.00818C9.19 6.83545 9.09 6.59909 9.09 6.36273C9.09 6.12636 9.19 5.89 9.35364 5.71727ZM10.9827 13.9945C10.9827 14.4964 10.5755 14.9036 10.0736 14.9036C9.57091 14.9036 9.16455 14.4964 9.16455 13.9945V10.3582C9.16455 9.85636 9.57182 9.44909 10.0736 9.44909C10.5755 9.44909 10.9827 9.85636 10.9827 10.3582V13.9945Z" />
          </svg>
        </div>
      )}

      {isPopoverOpen || onHover ? (
        <div ref={ref} className={`infoToolTip-tooltip ${tooltipClass}`}>
          <div
            className={`absolute z-50 top-full ${
              left ? "left-0 " : "right-0 "
            }`}
            style={{
              marginTop: "5px",
              right: left ? "unset" : "10px",
              left: left ? "10px" : "unset",
              width: 0,
              height: 0,
              borderLeft: "10px solid transparent",
              borderRight: "10px solid transparent",
              borderBottom: "10px solid #E4DDFA",
            }}
          />
          <div
            className={`z-50 text-base leading-normal font-normal text-left bg-purple-100 p-3 shadow-lg  absolute top-full w-100 ${
              left ? "left-0 " : "right-0 "
            }`}
            style={{
              minWidth: "299px",
              marginTop: "15px",
            }}
          >
            <div className="pr-6 pt-1">{message}</div>
            {!onHover && (
              <div
                className="absolute z-30 right-0 top-0 p-3"
                onClick={() => {
                  setPopoverOpen(false);
                }}
              >
                <svg
                  width="13"
                  height="13"
                  viewBox="0 0 13 13"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <path
                    fillRule="evenodd"
                    clipRule="evenodd"
                    d="M10.9063 12.1182L12.1182 10.9064L7.27087 6.05912L12.1182 1.21182L10.9063 -5.29705e-08L6.05904 4.8473L1.21174 -4.76735e-07L-8.30226e-05 1.21182L4.84722 6.05912L-8.34464e-05 10.9064L1.21174 12.1182L6.05904 7.27095L10.9063 12.1182Z"
                    fill="#3F2682"
                  />
                </svg>
              </div>
            )}
          </div>
        </div>
      ) : null}
    </div>
  );
}

export default InfoTooltip;
