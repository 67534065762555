import React from "react";
import { useUpdateDisplayedAlert } from "../api/displayedAlerts";
import { get, map, size } from "lodash-es";
import AlertMedicalVisitItem, {
  alertTitleMedicalVisit,
} from "./AlertItem/AlertMedicalVisitItem";
import AlertVehicleItem, {
  alertTitleVehicle,
} from "./AlertItem/AlertVehicleItem";
import AlertResidencePermitExpirationItem, {
  alertTitleResidencePermitExpiration,
} from "./AlertItem/AlertResidencePermitExpirationItem";
import AlertAffectedAccreditationItem, {
  alertTitleAffectedAccreditation,
} from "./AlertItem/AlertAffectedAccreditationItem";
import AlertHandicapItem, {
  alertTitleHandicap,
} from "./AlertItem/AlertHandicapItem";
import { AlertItemBlock } from "./AlertItem/AlertItem";
import { useGetUserAlerts } from "../api/user";
import Alert from "./Alert";
import { getLocaleDateString } from "../utils/date";
import AlertContractItem, {
  alertTitleContract,
} from "./AlertItem/AlertContractItem";
import AlertAbsenceItem, {
  alertTitleAbsence,
} from "./AlertItem/AlertAbsenceItem";
import AlertBadgeItem, { alertTitleBadge } from "./AlertItem/AlertBadgeItem";
import { useUserIri } from "../contexts/permissions";
import AlertCampaignItem, {
  alertTitleCampaign,
} from "./AlertItem/AlertCampaignItem";
import AlertCollaboratorItem, {
    alertTitleCollaborator,
} from "./AlertItem/AlertCollaboratorItem";

function AlertsList({
  userId,
  isArchive = false,
  filters,
  order,
  dateRange: { startDate, endDate },
}) {
  const userIri = useUserIri();
  const filter =
    endDate !== null
      ? {
          "dateNext[after]": new Date(startDate),
          "dateNext[before]": new Date(endDate),
          "order[dateNext]": order,
        }
      : {
          "order[dateNext]": order,
        };

  const { data: alerts } = useGetUserAlerts(userId, { ...filters, ...filter });

  if (size(alerts) <= 0) {
    return (
      <div className="mt-12">
        <Alert type="warning" message="aucune alerte" />
      </div>
    );
  }

  return (
    <div>
      {map(alerts, (alert, key) => (
        <AlertItemBlock
          alert={alert}
          key={key}
          isArchive={alert.archived}
          title={switchAlertTitle(alert)}
          filters={{ ...filters, ...filter }}
          showDelegate={testShowDelegate(alert, userIri)}
          typeCampaign={testIsTypeCampaign(alert)}
          typeCampaignEnd={testIsTypeCampaignEnd(alert)}
        >
          <SwitchAlert alert={alert} isArchive={isArchive} />
        </AlertItemBlock>
      ))}
    </div>
  );
}

function testShowDelegate(alert, userIri) {
  const nonDelegable = map(get(alert, "nonDelegableUsers"), "@id");
  if (nonDelegable.includes(userIri)) {
    return false;
  }
  return true;
}

function testIsTypeCampaign(alert) {
  return alert.typeAlert.classname.code === "Campaign";
}

function testIsTypeCampaignEnd(alert) {
  return alert.typeAlert.classname.code === "CampaignEnd";
}

function switchAlertTitle(alert) {
  if (alert.vehicleEvent) {
    return alertTitleVehicle(alert);
  }
  if (alert.medicalVisit) {
    return alertTitleMedicalVisit(alert);
  }
  if (alert.personalInformation) {
    return alertTitleResidencePermitExpiration(alert);
  }
  if (alert.affectedAccreditation) {
    return alertTitleAffectedAccreditation(alert);
  }
  if (alert.handicap) {
    return alertTitleHandicap(alert);
  }
  if (alert.contract) {
    return alertTitleContract(alert);
  }
  if (alert.absence) {
    return alertTitleAbsence(alert);
  }
  if (alert.badge) {
    return alertTitleBadge(alert);
  }
  if (alert.campaign) {
    return alertTitleCampaign(
      alert,
      alert.typeAlert.classname.code === "Campaign",
    );
  }
  if (alert.collaborator) {
    return alertTitleCollaborator(
      alert,
      alert.typeAlert.classname.code === "Collaborator",
    );
  }

  return {
    title: `${get(alert, "typeAlert.label", "")}`,
    subtitles: [
      {
        label: "Date de rappel",
        value: getLocaleDateString(alert, "reminderDate"),
      },
    ],
  };
}

export function SwitchAlert({ alert, ...props }) {
  if (alert.vehicleEvent) {
    return (
      <AlertVehicleItem
        alert={alert}
        key={alert["id"]}
        {...props}
        archiveHook={useUpdateDisplayedAlert}
      />
    );
  }
  if (alert.medicalVisit) {
    return (
      <AlertMedicalVisitItem
        alert={alert}
        key={alert["id"]}
        {...props}
        archiveHook={useUpdateDisplayedAlert}
      />
    );
  }
  if (alert.absence) {
    return (
      <AlertAbsenceItem
        alert={alert}
        key={alert["id"]}
        {...props}
        archiveHook={useUpdateDisplayedAlert}
      />
    );
  }
  if (alert.personalInformation) {
    return (
      <AlertResidencePermitExpirationItem
        alert={alert}
        key={alert["id"]}
        {...props}
        archiveHook={useUpdateDisplayedAlert}
      />
    );
  }
  if (alert.affectedAccreditation) {
    return (
      <AlertAffectedAccreditationItem
        alert={alert}
        key={alert["id"]}
        {...props}
        archiveHook={useUpdateDisplayedAlert}
      />
    );
  }
  if (alert.handicap) {
    return (
      <AlertHandicapItem
        alert={alert}
        key={alert["id"]}
        {...props}
        archiveHook={useUpdateDisplayedAlert}
      />
    );
  }
  if (alert.contract) {
    return (
      <AlertContractItem
        alert={alert}
        key={alert["id"]}
        {...props}
        archiveHook={useUpdateDisplayedAlert}
      />
    );
  }
  if (alert.badge) {
    return (
      <AlertBadgeItem
        alert={alert}
        key={alert["id"]}
        {...props}
        archiveHook={useUpdateDisplayedAlert}
      />
    );
  }
  if (alert.campaign) {
    return (
      <AlertCampaignItem
        alert={alert}
        key={alert["id"]}
        {...props}
        archiveHook={useUpdateDisplayedAlert}
      />
    );
  }
  if (alert.collaborator) {
    return (
      <AlertCollaboratorItem
        alert={alert}
        key={alert["id"]}
        {...props}
        archiveHook={useUpdateDisplayedAlert}
      />
    );
  }

  return null;
}

export default AlertsList;
