import { Form, Formik } from "formik";
import Input from "../components/Input";
import React from "react";
import { useCreateOrUpdateProcess } from "../api/process";
import EditButton from "../components/HistoryItem/EditButton";
import * as Yup from "yup";
import { get, noop } from "lodash-es";
import { toast } from "react-toastify";


const validateNewProcess = Yup.object().shape({
  label: Yup.string().required("Requis"),
});

export default function ProcessForm({
  isOut,
  process = null,
  afterSubmit = noop,
}) {
  const [createProcess] = useCreateOrUpdateProcess();
  
  const [isActivating, setIsActivating] = React.useState(false);
  
  return (
    <div className="mt-8">
      <Formik
        initialValues={{
          label: get(process, "label", ""),
        }}
        validationSchema={validateNewProcess}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          await createProcess({
            id: get(process, "id", null),
            data: {
              label: values.label,
              isOut: process ? process.isOut : isOut,
              active: process ? get(process, "active", false) : true,
            },
          });
          setSubmitting(false);
          resetForm();
          afterSubmit();
        }}
      >
        {({ isSubmitting, values }) => {
          return (
            <Form>
              <div className={`${!isSubmitting && !process ? "sm:flex mb-8 items-start" : ""}`}>
                <Input
                  name="label"
                  placeholder="Nom du processus"
                  className="mb-0 w-full mr-4"
                />
                {!isSubmitting && !process && (
                  <button
                    type="submit"
                    className="w-full sm:w-auto mt-4 sm:mt-0 flex-no-shrink btn btn--sm rounded-none whitespace-no-wrap"
                    disabled={isSubmitting}
                  >
                    {isSubmitting ? (
                      <div className="loader h-8 w-8 mx-auto" />
                    ) : process ? (
                      "Modifier"
                    ) : (
                      "Créer un nouveau processus"
                    )}
                  </button>
                  )}
                  { process && !isSubmitting && 
                  <div className="mt-4 align-top">
                      <EditButton
                        onDelete={
                          async () => {
                                setIsActivating(true);
                                await createProcess({
                                    id: get(process, "id", null),
                                    data: {
                                      label: values.label,
                                      isOut: process ? process.isOut : isOut,
                                      active: !get(process, "active", false)
                                    },
                                    });
                                setIsActivating(false);
                                toast.success("Mise à jour du type de process effectuée avec succès");
                              }
                        }
                        labelDelete={
                          get(process, "active", false) ? "Désactiver" : "Réactiver"
                        }
                        isSubmitting={isSubmitting || isActivating}
                        isEditing={true}
                        canEdit={false}
                        labelEdit="Modifier"
                      />
                      </div>
                }
                </div>

            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
