import * as Yup from "yup";

import { Form, Formik } from "formik";
import { get, map, noop, reduce } from "lodash-es";

import Alert from "../components/Alert";
import Input, { DateTimePickerInput } from "../components/Input";
import React from "react";
import { useCreateOrUpdateEvent, useDeleteEvent } from "../api/events";
import { usePermissionsChecker } from "../contexts/permissions";
import Button from "../components/Button";
import {
  downloadEventDocument,
  useCreateDocument,
  useDeleteEventDocument,
  useQueryEventDocuments,
} from "../api/eventDocument";
import {WysiwygTextEditor, WysiwygTextEditorNoForm} from "../components/WysiwygTextEditor";
import { useDesktop } from "../hooks/useDesktop";
import SelectCollaborator from "../components/SelectCollaborator";
import EditButton from "../components/HistoryItem/EditButton";
import { getFirstnameLastnameJob } from "../utils/names";
import TextArea from "../components/TextArea";
import DownloadOrUploadMulti from "../components/DownloadOrUploadMulti";
import Spinner from "../components/Spinner";
import DocumentOnlyBrowser from "../components/DocumentOnlyBrowser";
const validation = Yup.object().shape({
  label: Yup.string().required("Requis"),
  startDate: Yup.date().required("Requis"),
});

export default function CreateEvent({
  onSuccess = noop,
  collaborator = null,
  event = null,
}) {
  const readOnly = !usePermissionsChecker({
    permissions: ["kdix.actions.event.create"],
  });
  const [createEvent, { error }] = useCreateOrUpdateEvent();
  const [deleteEvent] = useDeleteEvent(true);
  const [createDocument] = useCreateDocument();
  const [isEditing, setIsEditing] = React.useState(!event);
  const isDesktop = useDesktop();
  
  return (
    <div>
      <Formik
        enableReinitialize
        initialValues={{
          label: get(event, "label", ""),
          comment: get(event, "comment", ""),
          startDate: get(event, "startDate", ""),
          files: [],
          collaborators: event
            ? [
                {
                  value: get(collaborator, "@id"),
                  label: getFirstnameLastnameJob(
                    { collaborator },
                    "collaborator"
                  ),
                },
                ...reduce(
                  event.collaborators,
                  (result, c) => {
                    if (c["@id"] !== collaborator["@id"]) {
                      result.push({
                        value: c["@id"],
                        label: getFirstnameLastnameJob({ c }, "c"),
                      });
                    }
                    return result;
                  },
                  []
                ),
              ]
            : collaborator
            ? [collaborator]
            : [],
        }}
        validationSchema={validation}
        onSubmit={async (values, actions) => {
          try {
            const newEvent = await createEvent({
              id: event?.id ?? null,
              data: {
                label: values.label,
                comment: values.comment,
                collaborators: [...map(values.collaborators, "value")],
                startDate: values.startDate,
              },
            });
            if (newEvent?.id && values?.files) {
              for (const file of values.files) {
                await createDocument({
                  file: file,
                  eventId: newEvent.id,
                });
              }
            }
            setIsEditing(false);
            actions.setSubmitting(false);
            onSuccess();
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
            actions.setSubmitting(false);
          }
        }}
      >
        {({ isSubmitting, errors: err, values, setFieldValue, resetForm }) => {
          return (
            <Form>
               <DateTimePickerInput
                name="startDate"
                label="Date"
                disabled={!isEditing || readOnly}
              />
              <SelectCollaborator
                name="collaborators"
                label="Participants"
                value={values.collaborators}
                isMulti={true}
                withJob={false}
                displayConcat={true}
                isDisabled={!isEditing || readOnly}
                allowSelectAll={true}
                resetSelectAll={[collaborator]}
              />
              <Input
                type="text"
                name="label"
                label="Nom de l'évènement"
                readOnly={!isEditing || readOnly}
              />

            {isEditing && !readOnly ? (
              <WysiwygTextEditor
                  name="comment"
                  label="Commentaire"
                  setFieldValue={(val) => setFieldValue("comment", val)}
                  value={values.comment}
                  readOnly={!isEditing || readOnly}
                  toolbarHidden={!isEditing || readOnly}
                  isDesktop={isDesktop}
              />
              ) : (
                <div className="bg-gray-75">
                 <WysiwygTextEditorNoForm
                   value={values.comment}
                   readOnly={!isEditing || readOnly}
                 />
                 </div>
              )}
           
              {event?.["@id"] && (
                <React.Suspense fallback={<Spinner />}>
                  <div className="mb-2">
                    <DocumentOnlyBrowser
                      queryFolderHook={useQueryEventDocuments.bind(null, {
                        event: event?.["@id"],
                      })}
                      deleteDocumentHook={useDeleteEventDocument}
                      downloadDocumentFn={downloadEventDocument}
                      canDelete={true}
                      noText={true}
                    />
                  </div>
                </React.Suspense>
              )}
              {isEditing && (
                <DownloadOrUploadMulti
                  name="files"
                  accept="application/pdf, application/docx, image/jpeg, image/png, image/gif, image/webm"
                  isSubmitting={isSubmitting}
                  submitButton={false}
                  multiple
                />
              )}
              
              {error ? (
                <div className="my-2">
                  <Alert
                    type="error"
                    message={get(error, "title")}
                    details={get(error, "description")}
                  />
                </div>
              ) : null}
              {!event && !readOnly && (
                <div className="flex items-center mb-3">
                <Button
                  className={`btn btn--sm block`}
                  readOnly={readOnly}
                  isSubmitting={isSubmitting}
                  isForm={true}
                  type="submit"
                  textLabel="Créer l'évènement"
                />
                </div>
              )}
              {event && !readOnly && (
                <EditButton
                  isSubmitting={isSubmitting}
                  isEditing={isEditing}
                  onDelete={() => deleteEvent(event.id)}
                  onCancel={() => {
                    resetForm();
                    setIsEditing(!isEditing);
                  }}
                  onClick={() => {
                    setIsEditing(!isEditing);
                  }}
                  labelEdit="Valider"
                />
              )}
              
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}
