import { Form, Formik } from "formik";
import React, { useContext, useState } from "react";

import Alert from "../components/Alert";
import Button from "../components/Button";
import { Helmet } from "react-helmet";
import Input from "../components/Input";
import { ReactComponent as LogoIcon } from "../svgs/logo.svg";
import PermissionsContext from "../contexts/permissions";
import { get } from "lodash-es";
//import illustration from "../images/login_illustration.png";
//import illustration from "../images/login_illustration_noel.png";
import illustration from "../images/login_illustration.gif";
import { login } from "../api";
import { useQueryLogo } from "../api/logo";

export default function Login() {
  const [globalError, setGlobalError] = useState(false);
  const { setToken, setActions, setModules, setRefreshToken } =
    useContext(PermissionsContext);
  const { data: logo } = useQueryLogo();

    
  return (
    <div className="bg-white pt-12 h-full" style={{ height: "100vh" }}>
      <Helmet>
        <title>Connexion</title>
      </Helmet>
      <div className="text-center">
        {logo?.contentUrl ? (
          <img src={logo.contentUrl} className={`m-auto`} alt="logo" />
        ) : (
          <div className="text-3xl text-purple-600 font-bold mb-12">
            Bienvenue !
          </div>
        )}

        <img
          src={illustration}
          alt="illustration GIF"
          className="m-auto"
        />
      </div>
      <div className="p-8">
        <Formik
          initialValues={{
            username: "",
            password: "",
          }}
          onSubmit={async (values, actions) => {
            try {
              const {
                token,
                actions: userActions,
                modules,
                refreshToken,
              } = await login(values);
              setActions(JSON.stringify(userActions));
              setModules(JSON.stringify(modules));
              setRefreshToken(refreshToken);
              setToken(token);
              actions.setSubmitting(false);
              window.location.href = "/";
            } catch (error) {
              console.log({ error });

              const labelError = get(
                error,
                "data.detail",
                "Erreur lors de la connexion"
              );
              setGlobalError(
                labelError === "error.user.credential"
                  ? "Identifiant et/ou mot de passe incorrect"
                  : labelError === "Not Found"
                  ? "L'utilisateur n'existe pas ou n'est pas actif"
                  : labelError
              );
              actions.setSubmitting(false);
            }
          }}
        >
          {({ isSubmitting }) => (
            <Form className="max-w-xs m-auto">
              <Input
                label="Nom d'utilisateur"
                name="username"
                color="purple-600"
              />
              <Input
                type="password"
                label="Mot de passe"
                name="password"
                color="purple-600"
              />
              {globalError ? (
                <Alert message={globalError} type="error" className="mb-8" />
              ) : null}
              <Button className="w-full" type="submit">
                {isSubmitting ? (
                  <div className="loader h-8 w-8 mx-auto"></div>
                ) : (
                  "Se connecter"
                )}
              </Button>
            </Form>
          )}
        </Formik>
        <div className="flex justify-center mt-16">
          <LogoIcon className="fill-current text-purple-600" />
        </div>
      </div>
    </div>
  );
}
