import { DisplayInput } from "../../components/Input";
import HistoryItem from "./HistoryItem";
import React from "react";
import { ReactComponent as RequestIcon } from "../../svgs/request.svg";
import { get, map } from "lodash-es";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import TextArea from "../TextArea";
import Button from "../Button";
import { useCreateRequestComment } from "../../api/requestComment";
import { usePermissionsChecker } from "../../contexts/permissions";
import { getFirstnameLastnameJob } from "../../utils/names";
import { WysiwygTextEditorNoForm} from "../WysiwygTextEditor";


function StateThumbnails({
  consulted,
  isApproved,
  isComment,
  isDelegated,
  isPostponed,
}) {
  return (
    <div className={"flex flex-wrap text-white"}>
      {consulted > 0 ? (
        <div className={"bg-green-350 px-2 py-1 mb-1 mr-1 rounded"}>Vue</div>
      ) : (
        <div className={"bg-red-600 px-2 py-1 mb-1 mr-1 rounded"}>Non lue</div>
      )}
      {isApproved ? (
        <div className={"bg-gray-200 px-2 py-1 mb-1 mr-1 rounded"}>Traitée</div>
      ) : null}
      {isComment ? (
        <div className={"bg-green-500 px-2 py-1 mb-1 mr-1 rounded"}>
          Commentée
        </div>
      ) : null}
      {isDelegated ? (
        <div className={"bg-green-800 px-2 py-1 mb-1 mr-1 rounded"}>
          Déléguée
        </div>
      ) : null}
      {isPostponed ? (
        <div className={"bg-green-900 px-2 py-1 mb-1 mr-1 rounded"}>
          Reportée
        </div>
      ) : null}
    </div>
  );
}

function RequestHistoryItem({ request, date, isLeft }) {
  const [addComment] = useCreateRequestComment();

  const consulted = map(get(request, "consultedBy"), "@id").length;
  const isApproved = get(request, "approved");
  const isComment = request.comments.length > 0;
  const createdAt = new Date(get(request, "createdAt")).toLocaleDateString();
  const reminderAt = new Date(get(request, "reminderAt")).toLocaleDateString();
  const isPostponed = createdAt !== reminderAt;
  const isDelegated = !!get(request, "associatedUser", false);
  const readOnlyRequest =
    !usePermissionsChecker({
      permissions: [
        "kdix.actions.request.edit",
        "kdix.actions.request.edit.agency",
        "kdix.actions.request.edit.department",
        "kdix.actions.request.edit.service",
        "kdix.actions.request.edit.own",
      ],
    }) || get(request, "approved", false);

  return (
    <HistoryItem
      title={
        get(request, "type.label", "") !== ""
          ? `Demande - ${get(request, "type.label", "")}`
          : "Demande"
      }
      Icon={RequestIcon}
      backgroundIcon="bg-purple-600"
      date={`${date ? new Date(date).toLocaleDateString() : ""}`}
      subTitle={
        <StateThumbnails
          consulted={consulted}
          isApproved={isApproved}
          isComment={isComment}
          isPostponed={isPostponed}
          isDelegated={isDelegated}
        />
      }
      itemId={request.id}
      isToggle={true}
      textColor={"text-purple-600"}
      isLeft={isLeft}
    >
      <div className={"flex flex-wrap"}>
        <div className={"w-full lg:pr-2"}>
          <DisplayInput
            label="Type de demande"
            value={get(request, "type.label")}
          />
        </div>
      </div>

      <WysiwygTextEditorNoForm
        label="Message"
        value={get(request, "message")}
        readOnly={true}
      />
      {request.comments.length > 0 && (
        <div className="font-bold py-2">Commentaires :</div>
      )}
      <div className="pb-6">
        {map(
          request.comments,
          ({ createdAt, comment, createdBy: { collaborator } }, index) => (
            <div
              key={index}
              className="py-3 border-b border-gray-200 last:border-b-0"
            >
              <div className="font-bold py-1">
                {getFirstnameLastnameJob({ collaborator }, "collaborator")}
                <span className="text-sm italic font-normal pl-1">
                  {new Date(createdAt).toLocaleDateString()}
                </span>
              </div>
              <div>{comment}</div>
            </div>
          )
        )}
        <Formik
          initialValues={{
            comment: "",
          }}
          onSubmit={async ({ comment }, actions) => {
            try {
              await addComment({
                comment: comment,
                request: get(request, "@id"),
              });
              toast.success("Commentaire ajouté avec succès");
              actions.resetForm();
            } catch (error) {
              map(get(error, "violations"), (e) => {
                actions.setFieldError(e.propertyPath, e.message);
              });
            }
          }}
        >
          {({ isSubmitting, submitForm }) => (
            <Form>
              {!readOnlyRequest && (
                <>
                <TextArea
                  label="Commentaire"
                  name="comment"
                  textareaClassName="comment"
                  readOnly={readOnlyRequest} />
                  
                 <div className="mt-4">
                    <Button
                    className={`btn btn--sm align-top w-half ml-4`}
                    isSubmitting={isSubmitting}
                    isForm={true}
                    type="submit"
                    textLabel="Commenter" />
                  </div>
                </>               
              )}
              </Form>
          )}
        </Formik>
      </div>
    </HistoryItem>
  );
}

export default RequestHistoryItem;
