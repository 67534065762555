import React from "react";
import { useToggle } from "react-use";
import { get, map } from "lodash-es";
import Block from "../Block";
import { Form, Formik } from "formik";
import { toast } from "react-toastify";
import { ReactComponent as TraiterIcon } from "../../svgs/traiter.svg";
import { ReactComponent as ModifierIcon } from "../../svgs/edit.svg";
import Button from "../Button";
import LargeButtonAction from "../Button/LargeButtonAction";
import {
  getFirstnameLastname,
  getFirstnameLastnameJob,
} from "../../utils/names";
import { getLocaleDateString } from "../../utils/date";
import ExternalLink from "../ExternalLink";
import { ReactComponent as CommenterIcon } from "../../svgs/commenter.svg";
import Textarea from "../TextArea";
import { useCreateStartedProcessTaskComment } from "../../api/startedProcessTaskComment";
import SelectCollaborator from "../SelectCollaborator";
import Spinner from "../Spinner";
import { DateTimePickerInput } from "../Input";
import { useCollaboratorId, useIsAdmin } from "../../contexts/permissions";

function StartedProcessTask({
  task,
  canEdit,
  updateHook,
  displayUser = false,
  showCollaborator = true,
}) {
  const [on, toggle] = useToggle(false);
  const [formOn, toggleForm] = useToggle(false);
  const [formCommentOn, toggleFormComment] = useToggle(false);
  const collaboratorId = useCollaboratorId();
  const isAdmin = useIsAdmin();

  return (
    <Block customStyle="bg-white p-4 shadow mb-4 xl:mb-3">
      <div className={"flex cursor-pointer"} onClick={toggle}>
        <div className={"lg:flex w-full"}>
          <div>
            <p className={`font-bold`}>
              {showCollaborator && (
                <>
                  {getFirstnameLastnameJob(
                    task,
                    "startedProcessInOut.associatedCollaborator",
                  )}
                  {" / "}
                </>
              )}
              {get(task, "task.label", "Nouvelle tâche")}
            </p>
          </div>
          {get(task, "completed", false) ? (
          <div className={"lg:ml-auto mr-4 text-white lg:w-48 w-full"}>
            <div className={"flex flex-wrap"}>
                <div className={"bg-gray-200 px-2 py-1 mb-1 mr-1 rounded"}>
                  Traitée{" "}
                  {task.completedAt &&
                    `le ${getLocaleDateString(task, "completedAt")}`}
                </div>
            </div>
          </div>
          ) : null}
        </div>
        <div className={"lg:w-1/6 flex flex-col items-end md:ml-0"}>
          <div className={"text-right"}>
            <svg
              className={`transform fill-current text-green-600 ${
                on ? "rotate-180" : ""
              }`}
              width="20"
              height="12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.667 0L10 7.46 2.333 0 0 2.27 10 12l10-9.73L17.667 0z"
              />
            </svg>
          </div>
        </div>
      </div>
      <div className={`font-bold`}>
        {get(task, "startedProcessInOut.typeProcessOut.label")
          ? "Date de départ"
          : "Date d'arrivée"}
        {task.startedProcessInOut.date &&
          ` : ${getLocaleDateString(task, "startedProcessInOut.date")}`}
      </div>
      {displayUser ? (
        <div>
          <p className={`font-bold`}>
            {getFirstnameLastnameJob(
              task,
              "startedProcessInOut.associatedCollaborator",
            )}
          </p>
        </div>
      ) : null}
      {get(
        task,
        "startedProcessInOut.associatedCollaborator.companies",
        false,
      ) &&
      get(
        task,
        "startedProcessInOut.associatedCollaborator.companies[0]",
        false,
      ) ? (
        <div>
          {`Société : ${map(
            get(task, "startedProcessInOut.associatedCollaborator.companies"),
            (company) => company.label,
          ).join("/")}`}
        </div>
      ) : (
        <div>{`Société : Non définie`}</div>
      )}
      <div>
        {`Échéance : ${new Date(
          get(task, "date", "Nouvelle tâche"),
        ).toLocaleDateString()}`}
      </div>
      {get(task, "associatedUser.collaborator", false) ? (
        <div>
          {`Utilisateur associé : ${getFirstnameLastname(
            task,
            "associatedUser.collaborator",
          )}`}
        </div>
      ) : (
        <div>
          {`Aucun utilisateur associé - profil associé : ${get(
            task,
            "associatedPermissions[0].label",
          )}`}
        </div>
      )}
      {get(task, "startedProcessInOut.typeProcessOut.label")
        ? `Type de départ : ${get(
            task,
            "startedProcessInOut.typeProcessOut.label",
          )}`
        : ""}
      <div
        className={`${on && !formOn ? "block" : "hidden"} mt-2 cursor-default`}
      >
        {get(task, "task.url", false) ? (
          <div>
            Url :{" "}
            <ExternalLink
              to={task.task.url}
              className={`text-purple-600 no-underline hover:underline appearance-none bg-transparent w-full py-2 text-black leading-tight focus:outline-none `}
            >
              {task.task.url}
            </ExternalLink>
          </div>
        ) : null}
        {get(task, "task.comment", false) ? (
          <div>{`Description : ${get(task, "task.comment")}`}</div>
        ) : null}
        {get(task, "url", "") !== "" ? (
          <div className={"mb-4"}>
            <label className={`block text-black text-sm font-bold`}>Url</label>
            <div
              className={`appearance-none bg-transparent w-full py-2 text-black leading-tight focus:outline-none focus:border-red`}
              style={{
                minHeight: "37px",
                backgroundColor: "#f7f7ff",
              }}
            >
              {get(task, "url", "")}
            </div>
          </div>
        ) : null}
        {get(task, "comment", "") !== "" ? (
          <div className={"mb-4"}>
            <label className={`block text-black text-sm font-bold`}>
              Description
            </label>
            <div
              className={`appearance-none bg-transparent w-full py-2 text-black leading-tight focus:outline-none focus:border-red`}
              style={{
                minHeight: "37px",
                backgroundColor: "#f7f7ff",
              }}
            >
              {get(task, "comment", "")}
            </div>
          </div>
        ) : null}
        {task.comments.length > 0 && (
          <>
            <div className="font-bold py-2">Commentaires :</div>
            <div className="pb-6">
              {map(
                task.comments,
                (
                  { createdAt, comment, createdBy: { collaborator } },
                  index,
                ) => (
                  <div
                    key={index}
                    className="py-3 border-b border-gray-200 last:border-b-0"
                  >
                    <div className="font-bold py-1">
                      {getFirstnameLastname({ collaborator }, "collaborator")}
                      <span className="text-sm italic font-normal pl-1">
                        {new Date(createdAt).toLocaleDateString()}
                      </span>
                    </div>
                    <div>{comment}</div>
                  </div>
                ),
              )}
            </div>
          </>
        )}

        {isAdmin ||
        (canEdit &&
          !get(task, "completed", false) &&
          get(task, "associatedUser.collaborator", false) &&
          collaboratorId &&
          collaboratorId ===
            get(task, "associatedUser.collaborator.id", null)) ? (
          <div
            className={`${
              formOn || formCommentOn ? "hidden" : "block"
            } mt-2 flex justify-start text-white`}
          >
            <div
              className={`bg-green-800 cursor-pointer rounded w-half flex flex-col items-center md:w-largeBtn w-40  h-16 justify-around py-2`}
              onClick={toggleForm}
            >
              <ModifierIcon width={"26px"} height={"26px"} />
              <span className={"font-bold md:text-sm sm:text-md text-xxs"}>
                Modifier
              </span>
            </div>
            <div
              className={`bg-green-800 sm:ml-4 ml-1 largeBtnAction w-half md:w-largeBtn `}
              onClick={toggleFormComment}
            >
              <CommenterIcon width={"26px"} height={"26px"} />
              <span
                className={
                  "text-white font-bold md:text-sm sm:text-md text-xxs "
                }
              >
                Commenter
              </span>
            </div>
            <LargeButtonAction
              title="Traiter"
              Icon={TraiterIcon}
              onClick={async () => {
                await updateHook({
                  id: get(task, "id"),
                  data: {
                    completed: true,
                  },
                });
                toast.success("Tâche traitée avec succès");
              }}
            />
          </div>
        ) : null}
      </div>
      <div className={`${formOn ? "block" : "hidden"}`}>
        <React.Suspense fallback={<Spinner />}>
          <StartedProcessEditForm
            task={task}
            formToggle={toggleForm}
            updateHook={updateHook}
          />
        </React.Suspense>
      </div>
      <div className={`${formCommentOn ? "block" : "hidden"}`}>
        <CommentEditForm
          task={task}
          formToggle={toggleFormComment}
          updateHook={updateHook}
        />
      </div>
    </Block>
  );
}
export default StartedProcessTask;

function StartedProcessEditForm({ task, formToggle, updateHook }) {
  return (
    <Formik
      initialValues={{
        associatedUser: {
          label: task?.associatedUser
            ? getFirstnameLastname(task, "associatedUser.collaborator")
            : "",
          value: get(task, "associatedUser[@id]", ""),
        },
        date: get(task, "date", ""),
      }}
      onSubmit={async (values, actions) => {
        try {
          await updateHook({
            id: get(task, "id", ""),
            data: {
              associatedUser: values.associatedUser?.value
                ? values.associatedUser.value
                : null,
              date: values.date,
            },
          });
          toast.success("Mise à jour effectuée avec succès");
          formToggle();
        } catch (error) {
          map(get(error, "violations"), (e) => {
            actions.setFieldError(e.propertyPath, e.message);
          });
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      {({ values, isSubmitting, setFieldValue }) => {
        return (
          <Form>
            <SelectCollaborator
              label={"Utilisateur associé"}
              key={`associatedUser`}
              name={`associatedUser`}
              isUser
              value={values.associatedUser}
              className={"mb-4"}
              isClearable
              queryFilters={{
                "exists[collaborator]": "true",
                "order[collaborator.lastname]": "asc",
                "collaborator.active": "true",
              }}
            />
            <DateTimePickerInput
              name="date"
              label="Echéance"
              fullWidth={true}
              onChange={(value) => {
                  // Pour le pb de timezone qui change la date
                  value.setHours(0, 0, 0, 0);
                  setFieldValue("date", value, false);
                }}
            />
            <Button
              className={`btn--outline inline-block mx-2`}
              isSubmitting={isSubmitting}
              disabled={isSubmitting}
              isForm={true}
              type="submit"
              textLabel={"Enregistrer"}
            />
            <Button
              className={`bg-orange-500 hover:bg-orange-700 inline-block mx-2`}
              isSubmitting={isSubmitting}
              isForm={true}
              type="button"
              textLabel="Annuler"
              onClick={formToggle}
            />
          </Form>
        );
      }}
    </Formik>
  );
}
function CommentEditForm({ task, formToggle }) {
  const [addComment] = useCreateStartedProcessTaskComment();
  return (
    <Formik
      initialValues={{
        comment: "",
      }}
      onSubmit={async ({ comment }, actions) => {
        try {
          await addComment({
            comment: comment,
            startedProcessTask: get(task, "@id"),
          });
          toast.success("Mise à jour effectuée avec succès");
          formToggle();
        } catch (error) {
          map(get(error, "violations"), (e) => {
            actions.setFieldError(e.propertyPath, e.message);
          });
        } finally {
          actions.setSubmitting(false);
        }
      }}
    >
      {({ isSubmitting }) => {
        return (
          <Form>
            <Textarea
              label="Commentaire"
              name="comment"
              textareaClassName="comment"
              className={"mb-4"}
            />
            <Button
              className={`btn--outline inline-block mx-2`}
              isSubmitting={isSubmitting}
              disabled={isSubmitting}
              isForm={true}
              type="submit"
              textLabel={"Enregistrer"}
            />
            <Button
              className={`bg-orange-500 hover:bg-orange-700 inline-block mx-2`}
              isSubmitting={isSubmitting}
              isForm={true}
              type="button"
              textLabel="Annuler"
              onClick={formToggle}
            />
          </Form>
        );
      }}
    </Formik>
  );
}
