import React from "react";
import { map, size } from "lodash-es";
import { usePermissionsChecker, useUserId } from "../../contexts/permissions";
import { useUpdateStartedProcessTask } from "../../api/startedProcessTask";
import GoBackBtn from "../GoBackBtn";
import StartedProcessTask from "./StartedProcessTask";
import { useGetUserStartedProcessTask } from "../../api/user";
import Alert from "../Alert";
import { useDesktop } from "../../hooks/useDesktop";

export default function StartedProcessTasksDetail({ filters, dateRange }) {
  const userId = useUserId();
  
  const isDesktop = useDesktop();
  
  if (dateRange.endDate) {
    let debut = new Date(dateRange.startDate);
    let fin = new Date(dateRange.endDate);
    debut.setHours(0, 0, 0, 0);
    fin.setHours(23, 59, 59, 999);
    filters = {
      ...filters,
      "date[after]": debut,
      "date[before]": fin,
    };
  }
  const [updateHook] = useUpdateStartedProcessTask();

  const filtersIn = {
        ...filters,
        typeProcess: false,
  };
  const filtersOut = {
        ...filters,
        typeProcess: true,
  };
  const { data: tasks } = useGetUserStartedProcessTask(userId, filters);
  const { data: tasksIn } = useGetUserStartedProcessTask(userId, filtersIn);
  const { data: tasksOut } = useGetUserStartedProcessTask(userId, filtersOut);
  
  const canEdit = usePermissionsChecker({
    permissions: [
      "kdix.actions.process_in_out.view",
      "kdix.actions.process_in_out.view.agency",
      "kdix.actions.process_in_out.view.department",
      "kdix.actions.process_in_out.view.service",
      "kdix.actions.process_in_out.view.own",
    ],
  });

  if (size(tasksIn) <= 0 && size(tasksOut) <= 0) {
    return (
      <div>
        {filters.startedProcessId ? (
          <div className={"w-20"}>
            <GoBackBtn to={`/processus/liste`} className="flex items-center">
              <div className="font-bold">Retour</div>
            </GoBackBtn>
          </div>
        ) : null}
        <div className="mt-12">
          <Alert type="warning" message="Aucune tâche de processus" />
        </div>
      </div>
    );
  }

  return (
    <div>
      {filters.startedProcessId ? (
        <div className={"w-20"}>
          <GoBackBtn to={`/processus/liste`} className="flex items-center">
            <div className="font-bold">Retour</div>
          </GoBackBtn>
        </div>
      ) : null}
      {isDesktop ? (
        <>
        <div className="grid grid-cols-2 py-6">
          <div className="text-lg text-center uppercase">
            Arrivées
          </div>
          <div className="text-lg text-center uppercase">
            Départs
          </div>
        </div>
        <div className="xl:grid xl:grid-cols-2 xl:gap-5">
              <div> {map(tasksIn, (task) => {
                    return (
                        <StartedProcessTask
                            key={task.id}
                            task={{ ...task, processInOut: process["@id"] }}
                            canEdit={canEdit}
                            updateHook={updateHook}
                        />
                    );
                    })}
               </div>
               <div> {map(tasksOut, (task) => {
                    return (
                        <StartedProcessTask
                            key={task.id}
                            task={{ ...task, processInOut: process["@id"] }}
                            canEdit={canEdit}
                            updateHook={updateHook}
                        />
                    );
                    })}
               </div>
        </div>
        </>
       ) : (
        <div className={"mt-1"}>
        {map(tasks, (task) => {
          return (
              <StartedProcessTask
                  key={task.id}
                  task={{ ...task, processInOut: process["@id"] }}
                  canEdit={canEdit}
                  updateHook={updateHook}
              />
          );
          })}
        </div>       
        )}
      
    </div>
  );
}
