import React, { useState } from "react";
import { get, map, noop } from "lodash-es";
import { toast } from "react-toastify";
import { Form, Formik } from "formik";
import Input from "../components/Input";
import TextArea from "../components/TextArea";
import Button from "../components/Button";
import { useUpdateRequests } from "../api/requests";
import { useCreateRequestComment } from "../api/requestComment";
import { getFirstnameLastnameJob } from "../utils/names";
import {WysiwygTextEditorNoForm} from "../components/WysiwygTextEditor";

export default function RequestForm({
  request,
  onSuccess = noop,
  readOnly = false,
}) {
  const [approved, setApproved] = useState(false);
  const [addComment] = useCreateRequestComment();
  const [updateHook] = useUpdateRequests();
  if (request === null) return null;
  return (
    <Formik
      initialValues={{
        collaborateur:
          getFirstnameLastnameJob(request, "createdBy.collaborator") || "",
        createdAt: get(request, "createdAt")
          ? new Date(get(request, "createdAt")).toLocaleDateString()
          : "",
        type: get(request, "type.label") || "",
        message: get(request, "message") || "",
        comment: "",
      }}
      onSubmit={async (values, actions) => {
        try {
          if (approved) {
            await updateHook({
              id: get(request, "id"),
              data: {
                approved: approved,
              },
            });
            toast.success("Demande traitée avec succès");
          }

          if (values.comment) {
            await addComment({
              comment: values.comment,
              request: get(request, "@id"),
            });
            toast.success("Commentaire ajouté avec succès");
          }
          onSuccess();
          //setItem(null);
        } catch (error) {
          map(get(error, "violations"), (e) => {
            actions.setFieldError(e.propertyPath, e.message);
          });
        }
      }}
    >
      {({ isSubmitting, submitForm, values }) => {
        return (
          <Form>
            <Input
              type="text"
              label="Collaborateur"
              name="collaborateur"
              readOnly={true}
            />
            <Input
              type="text"
              label="Date de création"
              name="createdAt"
              readOnly={true}
            />
            <Input type="text" label="Catégorie" name="type" readOnly={true} />
        
            <WysiwygTextEditorNoForm
                  value={values.message}
                  readOnly={true}
              />
            {request.comments && (
              <div className="font-bold py-2">Commentaires :</div>
            )}
            <div className="pb-6">
              {map(
                request.comments,
                (
                  { createdAt, comment, createdBy: { collaborator } },
                  index
                ) => (
                  <div
                    key={index}
                    className="py-3 border-b border-gray-200 last:border-b-0"
                  >
                    <div className="font-bold py-1">
                      {getFirstnameLastnameJob(
                        { collaborator },
                        "collaborator"
                      )}
                      <span className="text-sm italic font-normal pl-1">
                        {new Date(createdAt).toLocaleDateString()}
                      </span>
                    </div>
                    <div>{comment}</div>
                  </div>
                )
              )}
            </div>

            <TextArea
              label="Commentaire"
              name="comment"
              textareaClassName="comment"
              readOnly={readOnly}
            />

            {!readOnly ? (
              <div className="mt-4">
                <Button
                  className={`btn btn--sm align-top w-half ml-4`}
                  isSubmitting={isSubmitting}
                  isForm={true}
                  type="submit"
                  textLabel="Commenter"
                />
                <Button
                  className={`btn btn--sm align-top w-half ml-4`}
                  isSubmitting={isSubmitting}
                  isForm={true}
                  type="button"
                  textLabel="Traiter"
                  onClick={() => {
                    setApproved(true);
                    submitForm();
                  }}
                />
              </div>
            ) : null}
          </Form>
        );
      }}
    </Formik>
  );
}
