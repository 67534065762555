import React from "react";
import { get, map } from "lodash-es";
import { getLocaleDateString } from "../../utils/date";
import { getFirstnameLastnameJob, getOnlyWork } from "../../utils/names";


export function alertTitleCollaborator(alert) {
  return {
    title: get(alert, "typeAlert.label", ""),
    subtitles: [
      {
        label: `Collaborateur`,
        value: getFirstnameLastnameJob(alert, "collaborator"),
      },
      {
        label: "Site(s)",
        value: map(alert.collaborator?.agencies, "label").join(" ,"),
      },
      {
        label: "Date de rappel",
        value: getLocaleDateString(alert, "reminderDate"),
      },
    ],
  };
}

function AlertCollaboratorItem({ alert }) {
    return (
        <>
          {alert.collaborator?.birthdate ? (
                <p className="mt-8">
                    <span className="font-bold">
                    Date de naissance :
                    </span>{" "}
                    {getLocaleDateString(alert, "collaborator.birthdate")}
                </p>
          ) : null}
            {get(alert, "typeAlert.comment") && (
                <p>
                    <span className="font-bold">Commentaire : </span>
                    {get(alert, "typeAlert.comment")}
                </p>
            )}
        </>
    );
}

export default AlertCollaboratorItem;
