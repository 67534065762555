import React from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { HeaderBar } from "../../components/HeaderBar";
import GoBackBtn from "../../components/GoBackBtn";
import Modal from "../../components/Modal";
import {
  downloadEvaluationPdf,
  useQueryEvaluationById,
} from "../../api/evaluations";
import Spinner from "../../components/Spinner";
import { getFirstnameLastname, getOnlyWork } from "../../utils/names";
import {
  downloadEvaluationHistoryExport,
  useQueryEvaluationHistory,
} from "../../api/evaluationHistory";
import { InlineBLockContent } from "../../components/InlineBlockContentSortable";
import { useDesktop } from "../../hooks/useDesktop";
import { map, reduce, size } from "lodash-es";
import StarScore from "../../components/StarScore";
import { getLocaleDateString } from "../../utils/date";
import Button from "../../components/Button";
import SkillDetailForm from "../../forms/SkillDetailForm";
import { WysiwygTextEditorNoForm } from "../../components/WysiwygTextEditor";
import Block from "../../components/Block";
import {
  EVALUATION_STATUS_CLOSED,
} from "../../utils/evaluationStatus";


function getScore(total, nbEval) {
  const score = total / nbEval;
  return Number.parseFloat(score).toFixed(2);
}

export default function EvaluationHistory(props) {
  const { id } = useParams();
  const { data: evaluation } = useQueryEvaluationById(id);
  const { data: history } = useQueryEvaluationHistory({
    evaluation: evaluation["@id"],
  });
  const isDesktop = useDesktop();

  const [currentSkill, setCurrentSkill] = React.useState(null);
  const gridTemplateColumn = "2fr 50% 1fr 1fr";
  const { totalCollaborator, totalEvaluator, nbEval } = reduce(
    history,
    (result, curr) => {
      result.totalEvaluator += curr.scoreEvaluator;
      result.totalCollaborator += curr.scoreCollaborator;
      result.nbEval++;
      return result;
    },
    { totalCollaborator: 0, totalEvaluator: 0, nbEval: 0 },
  );
  const scoreCollaborator = getScore(totalCollaborator, nbEval);
  const scoreEvaluator = getScore(totalEvaluator, nbEval);

  return (
    <div>
      <React.Suspense fallback={<Spinner />}>
        <Helmet>
          <title>
            Rapport d'évaluation {getFirstnameLastname(evaluation.collaborator)}
          </title>
        </Helmet>
        <HeaderBar smaller>
          <div className="mb-5">
            <span className="text-3xl font-bold">
              Rapport d'évaluation{" "}
              {getFirstnameLastname(evaluation.collaborator)}
            </span>
            <span className="text-xl">
              {" "}
              - Poste évalué : {getOnlyWork(evaluation.collaborator)}
            </span>
          </div>
          <div className="text-2xl font-bold">
            Date : {getLocaleDateString(evaluation, "createdAt")}
          </div>
        </HeaderBar>
        <div className="p-8 pb-24">
          <div className={"w-20 mb-10"}>
            <GoBackBtn
              to={
                props.pageBack
                  ? "/evaluations"
                  : `/rh/collaborateurs/${evaluation.collaborator.id}/evaluations`
              }
              className="flex items-center"
            >
              <div className="font-bold">Retour</div>
            </GoBackBtn>
          </div>
          <div className="mb-4 flex justify-between">
            <div className="font-bold">
              <div>Moyenne collaborateur : {scoreCollaborator} / 5</div>
              <div>Moyenne évaluateur : {scoreEvaluator} / 5</div>
            </div>
            <div>
              <Button
                type="button"
                className="btn--sm mr-4"
                isForm={true}
                textLabel="Export CSV"
                onClick={() => {
                  downloadEvaluationHistoryExport({
                    evaluation: evaluation["@id"],
                  },
                  "evaluation_" + evaluation.collaborator.lastname + "_" + evaluation.collaborator.firstname + "_" + getLocaleDateString(evaluation, "createdAt") );
                }}
              />
              {evaluation.status === EVALUATION_STATUS_CLOSED && ( <Button
                type="button"
                className="btn--sm"
                isForm={true}
                textLabel="Export PDF"
                onClick={async () => {
                  await downloadEvaluationPdf(id);
                }}
              />
              )}
            </div>
          </div>
          {isDesktop && (
            <div
              className={`grid divide-x text-white divide-white bg-gray-500 shadow mb-2 py-2 top-tab-header always-front`}
              style={{
                gridTemplateColumns: gridTemplateColumn,
              }}
            >
              <InlineBLockContent>Domaine</InlineBLockContent>
              <InlineBLockContent>Compétence</InlineBLockContent>
              <InlineBLockContent>Vision de l'évalué</InlineBLockContent>
              <InlineBLockContent>Vision de l'évaluateur</InlineBLockContent>
            </div>
          )}
          {map(history, (node) =>
            isDesktop ? (
              <div
                onClick={() => {
                  setCurrentSkill(node);
                }}
                key={node.id}
                className={`grid divide-x divide-gray-100 ${node.scoreCollaborator != node.scoreEvaluator ? "bg-white" : "bg-white"}  hover:bg-purple-50 shadow mb-2 py-2`}
                style={{
                  gridTemplateColumns: gridTemplateColumn,
                }}
              >
                <InlineBLockContent>{node.skillDomain}</InlineBLockContent>
                <InlineBLockContent>{node.skill}</InlineBLockContent>
                <InlineBLockContent className="flex justify-center">
                  <StarScore score={node.scoreCollaborator} />
                </InlineBLockContent>
                <InlineBLockContent className="flex justify-center">
                  <StarScore
                    score={node.scoreEvaluator}
                    colorStar="text-purple-600"
                  />
                </InlineBLockContent>
              </div>
            ) : (
              <div></div>
            ),
          )}

          <div className="mb-4 mt-3 p-4">
            <div className="flex justify-between mb-2">
              <div className="font-bold">
                Commentaire de {getFirstnameLastname(evaluation.collaborator)} :
              </div>
            </div>

            <div className="relative">
              <Block className="pr-16">
                <WysiwygTextEditorNoForm
                  readOnly={true}
                  value={evaluation.comment}
                />
              </Block>
            </div>

            <div className="flex justify-between mb-2">
              <div className="font-bold">
                Commentaire de{" "}
                {size(evaluation.evaluators) > 0
                  ? getFirstnameLastname(evaluation.evaluators[0])
                  : "évaluateur"}{" "}
                :
              </div>
            </div>

            <div className="relative">
              <Block className="pr-16">
                <WysiwygTextEditorNoForm
                  readOnly={true}
                  value={evaluation.commentEvaluator}
                />
              </Block>
            </div>

            <div className="flex justify-between mb-2">
              <div className="font-bold">Commentaire entretien :</div>
            </div>

            <div className="relative">
              <Block className="pr-16">
                <WysiwygTextEditorNoForm
                  readOnly={true}
                  value={evaluation.status === EVALUATION_STATUS_CLOSED ? evaluation.commentCompleted : "Entretien et clotûre évaluation A FAIRE"}
                />
              </Block>
            </div>
          </div>
        </div>
      </React.Suspense>
      <Modal
        title="Compétence évaluée"
        handleClose={(e) => {
          e.stopPropagation();
        }}
        isOpen={!!currentSkill}
        onRequestClose={() => setCurrentSkill(null)}
      >
        <SkillDetailForm skillEval={currentSkill} />
      </Modal>
    </div>
  );
}
