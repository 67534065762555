import React from "react";
import { Form, Formik } from "formik";
import { find, get, map, times } from "lodash-es";
import { usePermissionsChecker } from "../../contexts/permissions";
import GoBackBtn from "../GoBackBtn";
import Input from "../Input";
import TextArea from "../TextArea";
import { toast } from "react-toastify";
import { useToggle } from "react-use";
import ExpansionPanel from "../ExpansionPanel";
import * as Yup from "yup";
import Button from "../Button";
import Block from "../Block";
import { useCreateOrUpdateSkill, useDeleteSkill } from "../../api/skills";

const validation = Yup.object().shape({
  label: Yup.string().required("Requis"),
});

function Skill({
  updateHook,
  deleteHook,
  skill,
  canEdit,
  newFormToggle,
  isOpen = false,
}) {
  const nbLevels = 5;
  const levels = {};
  for (let i = 1; i <= nbLevels; i++) {
    levels[`level${i}`] = get(
      find(skill.skillLevels, { level: i }),
      "description",
      "",
    );
  }

  return (
    <ExpansionPanel
      className={"mt-2"}
      open={isOpen}
      title={get(skill, "label", "Nouvelle Compétence")}
    >
      <Formik
        initialValues={{
          label: get(skill, "label", ""),
          ...levels,
        }}
        validationSchema={validation}
        onSubmit={async (values, actions) => {
          try {
            await updateHook({
              id: get(skill, "id", ""),
              data: {
                label: values.label,
                skillLevels: times(nbLevels, (n) => ({
                  level: n + 1,
                  description: values[`level${n + 1}`],
                })),
                skillDomain: get(skill, "domain"),
              },
            });
            if (get(skill, "id", "") === "") {
              actions.resetForm();
              newFormToggle();
            }
          } catch (error) {
            map(get(error, "violations"), (e) => {
              actions.setFieldError(e.propertyPath, e.message);
            });
          } finally {
            actions.setSubmitting(false);
          }
          toast.success("Mise à jour effectuée avec succès");
        }}
      >
        {({ isSubmitting }) => {
          return (
            <Form>
              <div
                className={
                  "flex flex-wrap justify-between sm:flex-row flex-col"
                }
              >
                <Input
                  type="text"
                  label="Nom de la compétence"
                  name="label"
                  readOnly={!canEdit}
                  className={"flex-1 mr-8 mb-4 flex-grow-3"}
                />
              </div>
              {times(nbLevels, (n) => (
                <TextArea
                  key={n}
                  label={`Description niveau ${n} (Saisir "Pas de niveau" pour ne pas afficher ce niveau)`}
                  name={`level${n + 1}`}
                  textareaClassName="comment"
                  readOnly={!canEdit}
                />
              ))}

              <div className={`${canEdit ? "text-center mt-2" : "hidden"}`}>
                {canEdit ? (
                  <Button
                    className={`btn--outline inline-block mx-2`}
                    isSubmitting={isSubmitting}
                    isForm={true}
                    type="submit"
                    textLabel={
                      get(skill, "id", "") !== "" ? "Enregistrer" : "Créer"
                    }
                  />
                ) : null}
                {canEdit ? (
                  <Button
                    className={`btn--error inline-block mx-2`}
                    isSubmitting={isSubmitting}
                    isForm={true}
                    type="button"
                    textLabel="Supprimer"
                    onClick={() => {
                      const res = window.confirm(
                        "Êtes-vous sûre de vouloir supprimer cette compétence ?",
                      );
                      if (res) {
                        deleteHook(get(skill, "id"));
                      }
                    }}
                  />
                ) : null}
                {get(skill, "id", "") === "" ? (
                  <Button
                    className={`bg-orange-500 hover:bg-orange-700 inline-block mx-2`}
                    isSubmitting={isSubmitting}
                    isForm={true}
                    type="button"
                    textLabel="Annuler"
                    onClick={newFormToggle}
                  />
                ) : null}
              </div>
            </Form>
          );
        }}
      </Formik>
    </ExpansionPanel>
  );
}

export default function SkillDomainDetail({ domain }) {
  const [createOrUpdateHook] = useCreateOrUpdateSkill(domain.id.toString());
  const [deleteHook] = useDeleteSkill(domain.id.toString());
  const [newFormOn, newFormToggle] = useToggle(false);
  const canEdit = usePermissionsChecker({
    permissions: ["kdix.actions.skill_evaluation.edit_create"],
  });
  const skills = domain.skills;

  return (
    <div>
      <div className={"w-20"}>
        <GoBackBtn to={`/rh/competences`} className="flex items-center">
          <div className="font-bold">Retour</div>
        </GoBackBtn>
      </div>

      <div className={"mt-1"}>
        {map(skills, (skill) => {
          return (
            <Skill
              key={skill.id}
              skill={{ ...skill, domain: domain["@id"] }}
              canEdit={canEdit}
              updateHook={createOrUpdateHook}
              deleteHook={deleteHook}
            />
          );
        })}
      </div>

      {newFormOn ? (
        <Skill
          updateHook={createOrUpdateHook}
          skill={{ domain: domain["@id"] }}
          canEdit={canEdit}
          newFormToggle={newFormToggle}
          isOpen={true}
        />
      ) : (
        <div onClick={newFormToggle}>
          <Block
            customStyle={
              "text-center border-dashed border-2 border-gray-400 cursor-pointer"
            }
          >
            <Button
              className={`btn inline-block my-3`}
              isForm={false}
              type="button"
            >
              Ajouter une compétence
            </Button>
          </Block>
        </div>
      )}
    </div>
  );
}
