import React from "react";
import InfiniteScroll from "react-infinite-scroller";
import { get, flatMap, map, size } from "lodash-es";
import { useQueryInfiniteStartedProcessInOut, useUpdateStartedProcessInOut } from "../../api/startedProcessInOut";
import Spinner from "../Spinner";
import Alert from "../Alert";
import Button from "../Button";
import { Link } from "react-router-dom";
import { getLocaleDateString } from "../../utils/date";
import { getFirstnameLastnameJob } from "../../utils/names";
import { usePermissionsChecker } from "../../contexts/permissions";

const ProgressBar = ({ percent }) => {
  const containerStyles = {
    height: 17,
    maxWidth: "400px",
    borderRadius: 50,
  };

  const fillerStyles = {
    width: `${percent}%`,
    borderRadius: "inherit",
  };

  return (
    <div style={containerStyles} className="bg-purple-600 bg-opacity-25 w-full">
      <div style={fillerStyles} className=" h-full bg-purple-600 text-right" />
    </div>
  );
};

function StartedProcessItem({ process, isDesktop, even }) {
  const isOut = get(process, "processInOut.isOut");
  const tasks = get(process, "startedProcessTasks");
  const completedTasks = tasks.filter((t) => t.completed);
  const nbTasks = size(tasks);
  const nbCompleted = size(completedTasks);
  const nbRemaining = nbTasks - nbCompleted;
  const percent = Math.round((nbCompleted / nbTasks) * 100);
  const [updateProcess] = useUpdateStartedProcessInOut();
  
  const canComplete = usePermissionsChecker({
    permissions: [
      "kdix.actions.process_in_out.edit",
      "kdix.actions.process_in_out.edit.agency",
      "kdix.actions.process_in_out.edit.department",
      "kdix.actions.process_in_out.edit.service",
    ],
  });
  
  return (
    <div className={even ? "bg-white" : "bg-gray-50"}>
      <div
        className={"xl:w-8/12 mx-auto px-0 sm:px-8 md:px-24 xl:px-0 pt-5 pb-6"}
        style={isDesktop ? { maxWidth: "900px" } : {}}
      >
        <div className={"grid grid-cols-2"}>
          <div>
            <div className={"lg:flex w-full"}>
              <div className={"font-title font-black text-xl"}>
                {isOut ? "Processus de départ" : "Processus d'arrivée"}
              </div>
            </div>
            <div className={"font-bold text-lg pb-2"}>
              {getFirstnameLastnameJob(process, "associatedCollaborator")}
            </div>
            <div className={"pb-2"}>
              <ProgressBar percent={percent} />
            </div>
            {nbRemaining > 0 ? (
              <div className={"text-lg  pb-2"}>
                Il reste <span className={"font-bold"}>{nbRemaining}</span>{" "}
                tâche
                {nbRemaining > 1 ? "s" : ""} pour terminer le processus
              </div>
            ) : null}
            <Link
              className={"cursor-pointer"}
              to={`/processus/liste/${process.id}`}
            >
              <Button
                className={`btn--sm inline-block mx-2 w-largeBtn`}
                type="button"
                isForm={true}
                textLabel="Voir"
              />
            </Link>
            { canComplete && <Button
                className={`btn--sm inline-block mx-2 w-largeBtn`}
                type="button"
                isForm={true}
                textLabel="Terminer"
                onClick={async () => {
                    const res = window.confirm(
                      "Êtes-vous sûr de vouloir terminer ce processus ?"
                    );
                    if (res) {
                      try {
                          await updateProcess({
                            id: process.id,
                            data: {
                              completed: true,
                            },
                          });
                        } catch (error) {
                          console.warn(error);
                        }
                    }
                  }}
            />}
          </div>
          <div className={"text-white w-full"}>
            <div className={"flex flex-wrap justify-end"}>
              <div
                className={
                  "bg-purple-600 bg-opacity-50 px-2 py-1 mb-1 mr-1 rounded"
                }
              >
                {isOut ? "Date de départ" : "Date d'arrivée"} :{" "}
                {getLocaleDateString(process, "date")}
              </div>
              {get(process, "completed", false) ? (
                <div className={"bg-gray-200 px-2 py-1 mb-1 mr-1 rounded"}>
                  Terminé
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

function StartedProcessInOutList({ filters, isDesktop }) {
  const { data, fetchMore, canFetchMore } = useQueryInfiniteStartedProcessInOut(
    {
      "order[processInOut.isOut]": "asc",
      ...filters,
    }
  );
  const startedProcesses = flatMap(data, (page) => page["hydra:member"]);
  if (size(startedProcesses) <= 0) {
    return (
      <div className="mt-12">
        <Alert type="warning" message="Aucun processus" />
      </div>
    );
  }

//  console.log(startedProcesses);

  return (
    <div>
      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={() => {
          fetchMore();
        }}
        hasMore={canFetchMore !== false}
        loader={
          <div key={0} className=" relative">
            <Spinner />
          </div>
        }
      >
        {map(startedProcesses, (startedProcess, index) => {
          return (
            <StartedProcessItem
              even={index % 2 === 0}
              key={startedProcess["id"]}
              process={startedProcess}
              isDesktop={isDesktop}
            />
          );
        })}
      </InfiniteScroll>
    </div>
  );
}

export default StartedProcessInOutList;
