import React, { useState } from "react";
import { useDebounce, useToggle, useMap } from "react-use";
import { Helmet } from "react-helmet";
import DatePicker from "react-datepicker";
import CheckboxGroup from "../../components/CheckboxGroup";
import { HeaderBar } from "../../components/HeaderBar";
import Spinner from "../../components/Spinner";
import { flatMap, get, map, pull, size } from "lodash-es";
import Alert from "../../components/Alert";
import Modal from "../../components/Modal";
import { useDesktop } from "../../hooks/useDesktop";
import InlineBLockContentSortable, {
  InlineBLockContent,
} from "../../components/InlineBlockContentSortable";
import InfiniteScroll from "react-infinite-scroller";
import { Link } from "react-router-dom";
import { ReactComponent as PlusIcon } from "../../svgs/plus.svg";
import { ReactComponent as FilterIcon } from "../../svgs/filters.svg";
import { ReactComponent as TrashIcon } from "../../svgs/trash.svg";
import { ReactComponent as WatchIcon } from "../../svgs/ArrowUpRight.svg";
import { useQueryCampaignEvaluationsInfinite, useDeleteCampaignEvaluations } from "../../api/campaignEvaluations";
import { useQueryAgencies } from "../../api/agencies";
import { getLocaleDateString } from "../../utils/date";
import {
  getFirstnameLastname,
  getFirstnameLastnameJob,
} from "../../utils/names";
import { EVALUATION_STATUS } from "../../utils/evaluationStatus";
import ExpansionPanel from "../../components/ExpansionPanel";
import Button from "../../components/Button";
import InfoTooltip from "../../components/InfoTooltip";
import {
  usePermissionsChecker,
  useCollaboratorId } from "../../contexts/permissions";


function ButtonAddCampaign() {
  return (
    <Link
      to="/rh/competences/creation-campaign"
      className="absolute bg-green-600 hover:bg-green-800 text-white rounded-full w-12 h-12 -mb-5 mr-5 bottom-0 right-0 flex justify-center items-center focus:outline-none"
    >
      <PlusIcon className="w-4 h-4" />
    </Link>
  );
}


function CampaignsList({ query,dateRange: { endDate, startDate },
  filters }) {
  const [orderFilter, setOrderFilter] = useState({
    "order[campaign.endDate]": "desc",
  });
  const collaboratorId = useCollaboratorId();
  const hasPermissionViewAll = usePermissionsChecker({
    permissions: [
        "kdix.actions.skill_evaluation.viewall_campaign"
         ],
  });
  const hasPermissionViewEvaluations = usePermissionsChecker({
    permissions: [
        "kdix.actions.skill_evaluation.view_evaluations",
        "kdix.actions.skill_evaluation.view_evaluations.agency",
        "kdix.actions.skill_evaluation.view_evaluations.department",
        "kdix.actions.skill_evaluation.view_evaluations.service"
         ],
  });
  
  const filterCreateBy =
    !hasPermissionViewAll
      ? {
          "campaign.createdBy": collaboratorId,
        }
      : null;
   
 const filterDate =
    endDate !== null
      ? {
          "campaign.startDate[after]": new Date(startDate),
          "campaign.startDate[before]": new Date(endDate),
        }
      : {};
  
  const { data, fetchMore, canFetchMore } = useQueryCampaignEvaluationsInfinite(
    {
      orSearch_fullname: query,
      ...filterCreateBy,
      ...filterDate,
      ...orderFilter,
      ...filters,
    },
  );

  const isDesktop = useDesktop();

  const campaignEvaluations = flatMap(data, (page) => page["hydra:member"]);
  const [deleteCampaignEvaluations] = useDeleteCampaignEvaluations();

  if (size(campaignEvaluations) <= 0) {
    return (
      <div className="mt-12">
        <Alert type="warning" message="Aucun lancement d'évaluation'" />
      </div>
    );
  }

  const gridTemplateColumn = `20% 8% 8% 19% 19% 14% 8% 4%`;

  return (
    <div className="relative">
      {isDesktop && (
        <div
          className={`grid divide-x text-white divide-white bg-gray-500 shadow mb-2 py-2 sticky top-tab-header always-front`}
          style={{
            gridTemplateColumns: gridTemplateColumn,
          }}
        >
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName="campaign.name"
          >
            Nom
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName="campaign.startDate"
          >
            Date de lancement
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName="campaign.endDate"
          >
            Date de fin
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName="collaborator.lastname"
          >
            Collaborateur évalué
          </InlineBLockContentSortable>
          <InlineBLockContent>Évaluateur</InlineBLockContent>
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName="collaborator.agencies.label"
          >
            Site du collaborateur évalué
          </InlineBLockContentSortable>
          <InlineBLockContentSortable
            setOrderFilter={setOrderFilter}
            colName="evaluation.status"
          >
            Statut de l'évaluation
          </InlineBLockContentSortable>
          <InlineBLockContent className="justify-center">
              Action
            </InlineBLockContent>
        </div>
      )}
      <InfiniteScroll
        pageStart={1}
        initialLoad={false}
        loadMore={() => {
          fetchMore();
        }}
        hasMore={canFetchMore !== false}
        loader={
          <div key={0} className=" relative">
            <Spinner />
          </div>
        }
      >
        {map(
          campaignEvaluations,
          ({ campaign, collaborator, evaluation, ...node }) => {
            return isDesktop ? (
              <div
                key={node.id}
                className={`grid divide-x divide-gray-100 bg-white hover:bg-purple-50 shadow mb-2 py-2`}
                style={{
                  gridTemplateColumns: gridTemplateColumn,
                }}
              >
                <InlineBLockContent>{campaign.name}</InlineBLockContent>
                <InlineBLockContent>
                  {getLocaleDateString(campaign, "startDate")}
                </InlineBLockContent>
                <InlineBLockContent>
                  {getLocaleDateString(campaign, "endDate")}
                </InlineBLockContent>
                <InlineBLockContent>
                  {getFirstnameLastnameJob(collaborator)}
                </InlineBLockContent>
                <InlineBLockContent>
                  {size(collaborator.evaluators)>0
                    ? getFirstnameLastname(collaborator.evaluators[0])
                    : "Aucun"}
                </InlineBLockContent>
                <InlineBLockContent>
                  {map(collaborator.agencies, (agency, key) => (
                    <div key={key} className="mr-2">
                      {agency.label.toLowerCase()}
                    </div>
                  ))}
                </InlineBLockContent>
                <InlineBLockContent>
                  {evaluation === null
                    ? "Non démarrée"
                    : EVALUATION_STATUS[evaluation.status]}
                </InlineBLockContent>
                <InlineBLockContent>
                {evaluation === null ? (
                        <button
                        className="text-red-500 focus:outline-none focus:shadow-none"
                        onClick={async (e) => {
                          e.stopPropagation();
                          e.preventDefault();
                          try {
                            await deleteCampaignEvaluations(node.id);
                          } catch (error) {
                            console.warn(error);
                          }
                        }}
                      >
                        <TrashIcon className="w-6 h-6 text-red-500 fill-current" />
                      </button>
                        ) : hasPermissionViewEvaluations && (
                        <InfoTooltip message={"Ouvrir l'onglet Evaluations"} onHover={true}>
                            <Link
                              to={ `/rh/collaborateurs/${collaborator.id}/evaluations` }
                              className={`w-8 h-8 rounded-full flex justify-center items-center focus:outline-none bg-green-700`}
                            >
                              <WatchIcon className="w-4 h-4 fill-current text-white" />
                            </Link>
                          </InfoTooltip>         
                        ) }
                </InlineBLockContent>
              </div>
            ) : (
              <CampaignMobileCard campaign={campaign} node={node} />
            );
          },
        )}
      </InfiniteScroll>
    </div>
  );
}

function CampaignMobileCard({ campaign, node }) {
  return <ExpansionPanel key={node.id} title={campaign.name}></ExpansionPanel>;
}

export default function CampaignTracking() {
  const [query, setQuery] = useState("");
  const [debouncedQuery, setDebouncedQuery] = useState("");

  const [,] = useDebounce(
    () => {
      setDebouncedQuery(query);
    },
    500,
    [query],
  );
  
  const [dateRange, setDateRange] = useState({
    startDate: new Date(),
    endDate: null,
  });
  const [filtersOpen, toggleFilters] = useToggle(false);
  const [
    filters,
    { set: setFilter, remove: removeFilter, reset: resetFilters },
  ] = useMap({});
  const { data: agencies } = useQueryAgencies();

const [showAll, toggleShowAll] = useToggle(true);
const [showStarted, toggleShowStarted] = useToggle(false);
  const filtersList = [
    {
      label: "Site",
      key: "collaborator.agencies",
      options: map(agencies, (node) => ({
        label: node.label,
        value: node["@id"],
        id: node["@id"],
      })),
    },
    {
      label: "Statut",
      key: "evaluation.status",
      options: [
        {
          label: "En cours",
          id: "in_progress",
        },
        {
          label: "Terminée",
          id: "completed",
        },
        {
          label: "Validée",
          id: "closed",
        },
      ],
    },
  ];

  return (
    <div>
      <Helmet>
        <title>Suivi des évaluations</title>
      </Helmet>
      <HeaderBar
        sticky={true}
        smaller={true}
        title={<>Suivi des évaluations</>}
        buttons={<ButtonAddCampaign />}
      >
        <input
          type="text"
          name="query"
          value={query}
          className="mb-0 appearance-none bg-transparent border-b border-white w-full py-2  leading-tight focus:outline-none focus:border-red"
          placeholder="Rechercher une évaluation ou un collaborateur ou un évaluateur"
          onChange={(e) => setQuery(e.target.value)}
        />
      </HeaderBar>
      <div>
      <div className="px-8 mt-8 mb-4">
          <button
            className="flex items-center"
            onClick={() => toggleFilters(true)}
          >
            <FilterIcon />
            <span className="ml-4">Filtres</span>
          </button>
          
          <Button
              className={`p-1 px-2 btn-export ${showAll ? 'btn--outline' : 'btn--outline--reversed'} mx-1 my-1`}
              onClick={() => {
                resetFilters();
                toggleShowAll(true);
              }}
          >
            Tout
          </Button>
          <Button
              className={`p-1 px-2 btn-export ${!showAll && showStarted ? 'btn--outline' : 'btn--outline--reversed'} mx-1 my-1`}
              onClick={() => {
                toggleShowAll(false);
                toggleShowStarted(true);
                setFilter('exists[evaluation]', true);
              }}
          >
            Démarrées
          </Button>
          <Button
              className={`p-1 px-2 btn-export ${!showAll && !showStarted ? 'btn--outline' : 'btn--outline--reversed'} mx-1 my-1`}
              onClick={() => {
                toggleShowAll(false);
                toggleShowStarted(false);
                setFilter('exists[evaluation]', false);
              }}
          >
            Non démarrées
          </Button>
          
          
          <Modal
            title="Filtres"
            handleClose={(e) => {
              e.stopPropagation();
              toggleFilters(false);
            }}
            isOpen={filtersOpen}
            onRequestClose={() => toggleFilters(false)}
          >
            <div className="-mx-4 px-5">
            <div className="font-black mb-3">Sélectionner une période</div>
            <hr className="my-2" />
            <div className="-mx-4 px-5 text-center">
              <DatePicker
                selected={dateRange.startDate}
                onChange={([startDate, endDate]) => {
                  setDateRange({
                    startDate: startDate,
                    endDate: endDate,
                  });
                }}
                startDate={dateRange.startDate}
                endDate={dateRange.endDate}
                selectsRange
                inline
              />
            </div>
            {map(filtersList, ({ key, label, options }) => {
                return (
                  <CheckboxGroup
                    key={key}
                    label={label}
                    options={options}
                    className="py-4 odd:bg-purple-50 -mx-4 px-5"
                    selected={get(filters, key, [])}
                    onAdd={(option) => {
                      setFilter(key, [...get(filters, key, []), option]);
                    }}
                    onRemove={(option) => {
                      const newFilters = pull(get(filters, key, []), option);
                      if (size(newFilters) === 0) {
                        removeFilter(key);
                        return;
                      }
                      setFilter(key, newFilters);
                    }}
                  />
                );
              })}
              </div>
            <div>
              <div
                className="mt-3 underline cursor-pointer"
                onClick={() => {
                  resetFilters();
                  setDateRange({
                    startDate: new Date(),
                    endDate: null,
                  });
                }}
              >
                Supprimer les filtres
              </div>
            </div>

            <button
              className="btn mt-5 w-full"
              type="button"
              onClick={() => toggleFilters(false)}
            >
              Appliquer les filtres
            </button>
          </Modal>
        </div>
        <div className="px-8 mb-48 mt-16">
          <React.Suspense fallback={<Spinner />}>
            <CampaignsList 
            query={debouncedQuery}
            dateRange={dateRange}
            filters={filters}
            />
          </React.Suspense>
        </div>
      </div>
    </div>
  );
}
