export const EVALUATION_STATUS_IN_PROGRESS = "in_progress";
export const EVALUATION_STATUS_COMPLETED = "completed";
export const EVALUATION_STATUS_CLOSED = "closed";

const NIVEAU1 = "Aucune compétence";
const NIVEAU2 = "Notions";
const NIVEAU3 = "Compétences à approfondir";
const NIVEAU4 = "Compétences vérifiées";
const NIVEAU5 = "Maitrise complète";

const EVALUATION_STATUS_IN_PROGRESS_LABEL = "En cours";
const EVALUATION_STATUS_COMPLETED_LABEL = "Terminée";
const EVALUATION_STATUS_CLOSED_LABEL = "Évaluation clôturée";

export const EVALUATION_STATUS = {
  [EVALUATION_STATUS_IN_PROGRESS]: EVALUATION_STATUS_IN_PROGRESS_LABEL,
  [EVALUATION_STATUS_COMPLETED]: EVALUATION_STATUS_COMPLETED_LABEL,
  [EVALUATION_STATUS_CLOSED]: EVALUATION_STATUS_CLOSED_LABEL,
};

export const DESC_LEVELS = [NIVEAU1,NIVEAU2,NIVEAU3,NIVEAU4,NIVEAU5];

export function getDefaultLevel(level)
{
    switch (level) {
    case 1:
      return NIVEAU1;
    case 2:
      return NIVEAU2;
    case 3:
      return NIVEAU3;
    case 4:
      return NIVEAU4;
    case 5:
      return NIVEAU5;
    default:
      return "indéfini";
  }
}

export function getDefaultLevels() {
  return [
    {
      value: 1,
      label: NIVEAU1
    },
    {
      value: 2,
      label: NIVEAU2
    },
    {
      value: 3,
      label: NIVEAU3
    },
    {
      value: 4,
      label: NIVEAU4
    },
    {
      value: 5,
      label: NIVEAU5
    }
  ];
}
