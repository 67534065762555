import React from "react";
import { get } from "lodash-es";
import { Form, Formik } from "formik";
import Input from "../components/Input";
import TextArea from "../components/TextArea";
import { getDefaultLevel } from "../utils/evaluationStatus";


export default function SkillDetailForm({
  skillEval,
}) {
    
  if (skillEval === null) return null;
  return (
    <Formik
      initialValues={{
         skillDomain: get(skillEval, "skillDomain") || "",
         skill: get(skillEval, "skill") || "",
         levelCollaborator: "Niveau " + get(skillEval, "scoreCollaborator", 0) + " : " + (get(skillEval, "levelCollaborator", null)? get(skillEval, "levelCollaborator", "") : getDefaultLevel(get(skillEval, "scoreCollaborator", 0))),
         levelEvaluator: "Niveau " + get(skillEval, "scoreEvaluator", 0) + " : " + (get(skillEval, "levelEvaluator", null)? get(skillEval, "levelEvaluator", "") : getDefaultLevel(get(skillEval, "scoreEvaluator", 0))),
         commentCollaborator: get(skillEval, "commentCollaborator") || "",
         commentEvaluator: get(skillEval, "commentEvaluator") || "",
      }}
    >
    {({ isSubmitting }) => {
        return (
          <Form>
            <Input
              type="text"
              label="Domaine de compétences"
              name="skillDomain"
              readOnly={true}
            />
            <TextArea
              label="Compétence"
              name="skill"
              textareaClassName="comment"
              readOnly={true}
            />
            <TextArea
              label="Vision de l'évalué"
              name="levelCollaborator"
              textareaClassName="comment"
              readOnly={true}
            />
            <TextArea
              label="Vision de l'évaluateur"
              name="levelEvaluator"
              textareaClassName="comment"
              readOnly={true}
            />
            <div className="font-bold py-2">Commentaires :</div>
            <div className="pb-3">
            <TextArea
              label="Commentaire collaborateur"
              name="commentCollaborator"
              textareaClassName="comment"
              readOnly={true}
            />
            </div>
            <div className="pb-3">
            <TextArea
              label="Commentaire évaluateur"
              name="commentEvaluator"
              textareaClassName="comment"
              readOnly={true}
            />
            </div>
          </Form>
          );
      }}
    </Formik>
  );
}

