import Block from "../Block";
import React from "react";
import { Link } from "react-router-dom";
import Button from "../Button";
import { size } from "lodash-es";
import { useGetUserStartedProcessTask } from "../../api/user";
import {
  useQueryCollaboratorHomeById,
  useUpdateCollaborator,
} from "../../api/collaborator";


function ProcessBlock({ userId, collaboratorId }) {
  const now = new Date();
  now.setHours(2,0,0,0);

  const filter = { completed: false, "date[before]": now };
  const { data: tasks } = useGetUserStartedProcessTask(userId, filter);
  const nbTasks = size(tasks);
  
  const { data: collab } = useQueryCollaboratorHomeById(collaboratorId);
  const lastRead = collab?.lastProcessRead ?? null;
  
  const nbNewProcessTasks = (collab && tasks)
    ? size(
        tasks.filter((task) => {
          return (
            lastRead !== null &&
            new Date(lastRead).getTime() < new Date(task.date).getTime()
          );
        }),
      )
    : 0;
   const [updateCollaborator] = useUpdateCollaborator();
  
  return (
    <>
      <Block className="text-center flex-grow bg-white">
        <Link
          to={`/taches`}
          className="flex flex-col flex-auto items-center justify-between flex-grow h-full"
        >
          <div
            className={`inline-flex bg-green-900 text-white text-3xl rounded-full font-bold w-16 h-16 items-center justify-center`}
          >
            {nbTasks}
          </div>
          { nbNewProcessTasks && nbNewProcessTasks > 0 ? (
          <span
            className="unread bg-red-700 rounded-full text-white text-xs absolute w-5 h-5 ml-1 -mt-3"
            style={{ marginLeft: "43px", marginTop: "5px" }}
          >
            &nbsp;
          </span>
        ) : null}
          <div className="mt-3 text-xl font-black">{`Arrivées/Départs`}</div>
          <div className="mt-2 text-base font-light">&nbsp;</div>
          <Button
            className={`font-bold p-1 focus:outline-none text-white w-full block`}
            onClick={async () => {
            try {
              if(collaboratorId && collab) {
                 await updateCollaborator({
                  id: collaboratorId,
                  data: {
                    lastProcessRead: new Date(),
                  },
                });
              }
            } catch (error) {
              console.log("Impossible de maj le collaborateur ");
            }
          }}
          >
            voir tout
          </Button>
        </Link>
      </Block>
    </>
  );
}

export default ProcessBlock;
