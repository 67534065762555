import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useHistory } from "react-router-dom";
import { useQueryCollaboratorById } from "../api/collaborator";
import { useQueryTypeProcessOut } from "../api/typeProcessOut";
import { useCreateStartedProcessInOut } from "../api/startedProcessInOut";
import { useDesktop } from "../hooks/useDesktop";
import { useQueryProcess } from "../api/process";
import { get, intersectionBy, map, reduce } from "lodash-es";
import { toast } from "react-toastify";
import { FieldArray, Form, Formik } from "formik";
import * as Yup from "yup";
import { DateTimePickerInput } from "../components/Input";
import Select from "../components/Select";
import Button from "../components/Button";
import { find } from "lodash-es";
import Block from "../components/Block";
import { useToggle } from "react-use";
import { useQueryPermissions } from "../api/permissions";
import { useQueryUsers } from "../api/user";
import ExternalLink from "../components/ExternalLink";
import { getFirstnameLastnameJob } from "../utils/names";
import { HeaderBar } from "../components/HeaderBar";
import { GoBackBtnHistory } from "../components/GoBackBtn";

const validationOut = Yup.object().shape({
  processInOut: Yup.mixed().required("Requis"),
  typeProcessOut: Yup.mixed().required("Requis"),
  tasks: Yup.array().of(
    Yup.object().shape({
      associatedPermissions: Yup.object().shape({
        value: Yup.string().required("Requis"),
      }),
      associatedUser: Yup.mixed().required("Requis"),
    })
  ),
  date: Yup.mixed().required("Requis"),
});

const validation = Yup.object().shape({
  processInOut: Yup.mixed().required("Requis"),
  tasks: Yup.array().of(
    Yup.object().shape({
      associatedPermissions: Yup.object().shape({
        value: Yup.string().required("Requis"),
      }),
      associatedUser: Yup.mixed().required("Requis"),
    })
  ),
  date: Yup.mixed().required("Requis"),
});

function computeDate(date, delay) {
  const resultDate = new Date(date);
  resultDate.setDate(resultDate.getDate() + delay);
  return resultDate;
}

const transformSelect = (r) => {
  return {
    value: get(r, "@id"),
    label: getFirstnameLastnameJob(r, "collaborator"),
  };
};

function TaskForm({ task, date, index, permissions, userSelect }) {
  const [on, toggle] = useToggle(true);
  return (
    <Block>
      <div
        className={"flex cursor-pointer"}
        onClick={(on) => {
          toggle();
        }}
      >
        <div className={"lg:flex w-full mb-4"}>
          <div>
            <div className="font-bold text-lg leading-tight relative">
              {task.label}
            </div>
          </div>
        </div>
        <div className={"lg:w-1/6 flex flex-col items-end md:ml-0"}>
          <div className={"text-right"}>
            <svg
              className={`transform fill-current text-green-600 ${
                on ? "rotate-180" : ""
              }`}
              width="20"
              height="12"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                fillRule="evenodd"
                clipRule="evenodd"
                d="M17.667 0L10 7.46 2.333 0 0 2.27 10 12l10-9.73L17.667 0z"
              />
            </svg>
          </div>
        </div>
      </div>
      {date ? (
        <div className={"mb-4"}>
          <label className={`block text-black text-sm font-bold`}>
            Échéance
          </label>
          <div
            className={`appearance-none bg-transparent w-full py-2 text-black leading-tight focus:outline-none focus:border-red`}
            style={{
              minHeight: "37px",
              backgroundColor: "#f7f7ff",
            }}
          >
            {computeDate(date, task.delay).toLocaleDateString()}
          </div>
        </div>
      ) : null}
      <div>
        <Select
          label={"Profil associé"}
          key={`tasks.${index}.associatedPermissions`}
          name={`tasks.${index}.associatedPermissions`}
          options={map(permissions, (node) => ({
            label: node.label,
            value: get(node, "@id"),
          }))}
          transformResourceValue={(value) => {
            return map(intersectionBy(permissions, value, "@id"), (item) => ({
              label: item.label,
              value: item["@id"],
            }));
          }}
          className="mb-4 flex-1"
        />
      </div>
      <div className={`${on ? "block" : "hidden"} mt-2 cursor-default`}>
        <Select
          label={"Utilisateur associé"}
          isClearable
          key={`tasks.${index}.associatedUser`}
          name={`tasks.${index}.associatedUser`}
          options={userSelect}
          className={"mb-4"}
        />
        {task.url && (
          <div className={"mb-4 break-all"}>
            <label className={`block text-black text-sm font-bold`}>Url</label>
            <ExternalLink
              to={task.url}
              className={`text-purple-600 no-underline hover:underline appearance-none bg-transparent w-full py-2 text-black leading-tight focus:outline-none focus:border-red`}
              style={{
                minHeight: "37px",
                backgroundColor: "#f7f7ff",
              }}
            >
              {task.url}
            </ExternalLink>
          </div>
        )}
        {get(task, "comment", "") !== "" ? (
          <div className={"mb-4"}>
            <label className={`block text-black text-sm font-bold`}>
              Description
            </label>
            <div
              className={`appearance-none bg-transparent w-full py-2 text-black leading-tight focus:outline-none focus:border-red`}
              style={{
                minHeight: "37px",
                backgroundColor: "#f7f7ff",
              }}
            >
              {get(task, "comment", "")}
            </div>
          </div>
        ) : null}
      </div>
    </Block>
  );
}

function StartedProcessInOut({ isOut }) {
  const { id: idCollaborator } = useParams();
  const { data: collaborator } = useQueryCollaboratorById(idCollaborator);
  const { data: typeProcessOut } = useQueryTypeProcessOut();
  const [createStartedProcessInOut] = useCreateStartedProcessInOut();
  const { data: permissions } = useQueryPermissions();
  const isDesktop = useDesktop();
  const { data: process } = useQueryProcess({
    isOut,
    active:true,
  });
  const { data: users } = useQueryUsers({
    "exists[collaborator]": "true",
    "order[collaborator.lastname]": "asc",
    "collaborator.active": "true",
  });
  const userSelect = reduce(
    users,
    (result, user) => {
      result.push(transformSelect(user));
      return result;
    },
    []
  );
  const [selectedProcess, setSelectedProcess] = useState(null);
  const history = useHistory();

  return (
    <>
      <Helmet>
        <title>Processus {isOut ? "de départ" : "d'arrivée"}</title>
      </Helmet>
      <HeaderBar>
        Processus {isOut ? "de départ" : "d'arrivée"}
        <div className="text-xl pt-4">
          {getFirstnameLastnameJob({ collaborator }, "collaborator")}
        </div>
      </HeaderBar>

      <div
        className={"xl:w-8/12 mx-auto px-0 sm:px-8 md:px-24 xl:px-0 "}
        style={isDesktop ? { maxWidth: "900px" } : {}}
      >
        <div className={"mt-4"}>
          <Formik
            initialValues={{
              date: null,
              processInOut: null,
              typeProcessOut: null,
              tasks: [],
            }}
            validationSchema={isOut ? validationOut : validation}
            onSubmit={async (values, actions) => {
              try {
                await createStartedProcessInOut({
                  date: values.date,
                  associatedCollaborator: collaborator["@id"],
                  typeProcessOut: values.typeProcessOut
                    ? values.typeProcessOut.value
                    : null,
                  processInOut: values.processInOut.value
                    ? values.processInOut.value
                    : null,
                  startedProcessTasks: reduce(
                    values.tasks,
                    (result, task) => {
                      result.push({
                        associatedUser: task.associatedUser
                          ? task.associatedUser.value
                          : null,
                        associatedPermissions: [
                          task.associatedPermissions.value,
                        ],
                        task: task.id,
                        date: computeDate(values.date, task.delay),
                      });
                      return result;
                    },
                    []
                  ),
                });
                toast.success("Processus démarré avec succès");
                actions.resetForm();
                history.push(`/processus/liste`);
              } catch (error) {
                map(get(error, "violations"), (e) => {
                  actions.setFieldError(e.propertyPath, e.message);
                });
              } finally {
                actions.setSubmitting(false);
              }
            }}
          >
            {({ values, isSubmitting, setValues, errors }) => {
              return (
                <Form>
        <Block>
          <GoBackBtnHistory className="flex items-center">
            <div className="font-bold">Retour</div>
          </GoBackBtnHistory>
          <div className="mt-4">
                  <Block>
                    <Select
                      label={"Processus"}
                      key={"processInOut"}
                      name={"processInOut"}
                      options={map(process, (node) => ({
                        label: node.label,
                        value: get(node, "@id"),
                      }))}
                      transformResourceValue={(value) => {
                        return map(
                          intersectionBy(process, value, "@id"),
                          (item) => ({
                            label: item.label,
                            value: item["@id"],
                          })
                        );
                      }}
                      onChange={(fieldValue) => {
                        if (fieldValue) {
                          const selectedProcess = find(process, function (p) {
                            return p["@id"] === fieldValue.value;
                          });
                          const tasks = map(
                            get(selectedProcess, "tasks", []),
                            (task) => {
                              return {
                                associatedPermissions: {
                                  label:
                                    task.associatedPermissions?.[0]?.label ??
                                    "",
                                  value:
                                    task.associatedPermissions?.[0]?.["@id"] ??
                                    "",
                                },
                                associatedUser: null,
                                id: task["@id"],
                                delay: task.delay,
                              };
                            }
                          );
                          setValues({
                            ...values,
                            processInOut: fieldValue,
                            tasks,
                          });
                          setSelectedProcess(selectedProcess);
                        }
                      }}
                      className="mb-4 flex-1"
                    />
                    {isOut ? (
                      <Select
                        label={"Type de fin de contrat"}
                        key={"typeProcessOut"}
                        name={"typeProcessOut"}
                        options={map(typeProcessOut, (node) => ({
                          label: node.label,
                          value: get(node, "@id"),
                        }))}
                        className="mb-4 flex-1"
                      />
                    ) : null}

                    <DateTimePickerInput
                      label={`Date ${isOut ? "de départ" : "d'arrivée"}`}
                      name="date"
                      key={"date"}
                      fullWidth={true}
                    />
                  </Block>
                   </div>
                  </Block>
                  <FieldArray name="tasks">
                    {() =>
                      map(get(selectedProcess, "tasks", []), (task, index) => {
                        return (
                          <TaskForm
                            key={task.id}
                            task={task}
                            date={values.date}
                            index={index}
                            permissions={permissions}
                            userSelect={userSelect}
                          />
                        );
                      })
                    }
                  </FieldArray>

                  <Button
                    className={`btn--sm inline-block mx-2 w-xlBtn`}
                    isSubmitting={isSubmitting}
                    disabled={isSubmitting}
                    isForm={true}
                    type="submit"
                    textLabel={"Valider"}
                  />
                 
                </Form>
              );
            }}
          </Formik>
        </div>
      </div>
    </>
  );
}
export default StartedProcessInOut;
